<template>
  <div class="vipClub">
    <div class="box-VipClub2">
      <div class="box-VipClub-title">
        <h4>
          <strong>{{ $t('Member.142') }} EUSD</strong>
        </h4>
      </div>
      <div class="box-VipClub-content">
        <span class="h1 animation-expandOpen game-title-2" v-if="Balance">
          {{ Balance['EUSD'] ? Balance['EUSD'].toFixed(2) : 0 }}</span
        >
      </div>
    </div>
    <div class="box-VipClub2" v-if="checkEBP">
      <div class="box-VipClub-title">
        <h4>
          <strong>{{ $t('Member.142') }} EBP</strong>
        </h4>
      </div>
      <div class="box-VipClub-content">
        <span class="h1 animation-expandOpen game-title-2" v-if="Balance">
          {{ Balance['EBP'] ? Balance['EBP'].toFixed(2) : 0 }}</span
        >
      </div>
    </div>
    <div class="box-VipClub2">
      <div class="box-VipClub-title">
        <h4>
          <strong>{{ $t('Member.143') }}</strong>
        </h4>
      </div>
      <div class="box-VipClub-content">
        <span class="h1 animation-expandOpen game-title-2" v-if="Total">{{
          Total.member
        }}</span>
      </div>
    </div>
    <!-- <div class="box-VipClub2">
      <div class="box-VipClub-title">
        <h4>
          <strong>Member Agency (IB)</strong>
        </h4>
      </div>
      <div class="box-VipClub-content member-ib">
        <div class="img-div" v-if="Agency.PackageImage">
          <img :src="Agency.PackageImage" alt="" />
        </div>
        <div class="detail-div" v-if="Agency.PackageName">
          <span class="mx-2">{{ Agency.PackageName }}</span>
          <button class="btn btn-success mx-2" @click="openListPackage()">
            Buy Agency
          </button>
        </div>
      </div>
    </div> -->
    <div class="box-VipClub2">
      <div class="box-VipClub-title">
        <h4>
          <strong>{{ $t('Member.144') }}</strong>
        </h4>
      </div>
      <div class="box-VipClub-content" v-if="Info.id">
        <input
          type="text"
          class="input-custom"
          ref="link-ref"
          v-model="url"
          readonly
        />
        <button
          v-clipboard:copy="url"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          class="button-custom"
        >
          {{ $t('Member.145') }}
        </button>
      </div>
      <div class="box-VipClub-content" v-else>
        <input
          type="text"
          class="input-custom2"
          ref="link-ref"
          v-model="url"
          readonly
        />
      </div>
    </div>
    <div class="box-VipClub3">
      <div class="box-VipClub-title">
        <h4>{{ $t('Member.146') }}</h4>
      </div>
      <div class="box-VipClub-content">
        <b-card no-body>
          <b-tabs card>
            <b-tab v-for="tab in tabs" :key="tab.name" :title="tab.value">
              <b-card-text v-if="tab.name === 'list'">
                <div class="tab-content listMember">
                  <div class="block-section">
                    <div class="row">
                      <div class="form-group col-md-3">
                        <input
                          v-model="formListMember.user_id"
                          type="text"
                          class="form-control"
                          :placeholder="$t('Member.158')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <input
                          v-model="formListMember.user_email"
                          type="text"
                          class="form-control"
                          :placeholder="$t('Member.156')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <datepicker
                          v-model="formListMember.created_at"
                          value-type="format"
                          input-class="form-control"
                          :placeholder="$t('Member.151')"
                          type="datetime"
                        ></datepicker>
                      </div>
                      <div class="form-group col-md-3">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="onSearch"
                        >
                          <i class="fa fa-search"></i> {{ $t('Member.147') }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table
                      class="table table-vcenter table-bordered table-hover table-striped table-condensed"
                      v-if="ListMember && ListMember.length > 0"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>{{ $t('InforUser.35') }}</th>
                          <th>F</th>
                          <th>{{ $t('Member.148') }}</th>
                          <th>{{ $t('Member.149') }}</th>
                          <th>{{ $t('Member.150') }}</th>
                          <th>{{ $t('Member.151') }}</th>
                          <th>{{ $t('Member.152') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="member in ListMember" :key="member.User_ID">
                          <td>{{ member.User_ID }}</td>
                          <td>{{ member.UserEmail }}</td>
                          <td>{{ member.F }}</td>
                          <td>{{ member.LevelAgency }}</td>
                          <td>{{ member.VolumeTrade }}</td>
                          <td>
                            {{
                              member.AddressWallet
                                ? member.AddressWallet
                                : 'Not update'
                            }}
                          </td>
                          <td>{{ member.Created_Date }}</td>
                          <td>{{ member.last_login || 'NA' }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-else class="block-section">
                      {{ $t('Member.153') }}
                    </div>
                  </div>
                  <tr v-if="TotalPage > 1">
                    <td colspan="5" class="text-right">
                      <paginate
                        v-model="formListMember.page"
                        :page-count="TotalPage"
                        :click-handler="onPagination"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                      ></paginate>
                    </td>
                  </tr>
                </div>
              </b-card-text>
              <b-card-text v-if="tab.name === 'add'" class="form-add-member">
                <b-form @submit.prevent="onAddMember">
                  <p>{{ $t('InforUser.35') }}</p>
                  <b-form-input
                    type="email"
                    v-model="formAddMember.email"
                    required
                    @keyup="checkRequiredToShowBtnAddMember"
                  ></b-form-input>
                  <template v-if="false">
                    <p>{{ $t('InforUser.49') }}</p>
                    <b-form-input
                      type="password"
                      v-model="formAddMember.password"
                      required
                      @keyup="checkRequiredToShowBtnAddMember"
                    ></b-form-input>
                    <p>{{ $t('Transaction.88') }}</p>
                    <b-form-input
                      type="password"
                      v-model="passwordConfirm"
                      required
                      @keyup="checkRequiredToShowBtnAddMember"
                    ></b-form-input>
                  </template>
                  <p>{{ $t('Register.111') }}</p>
                  <b-form-input
                    readonly
                    type="number"
                    v-model="Info.id"
                    required
                    @keyup="checkRequiredToShowBtnAddMember"
                  ></b-form-input>
                  <button type="submit" class="button" v-if="showBtnAddMember">
                    {{ $t('Member.154') }}
                  </button>
                  <button type="button" class="button btn-disable" v-else>
                    {{ $t('Member.154') }}
                  </button>
                </b-form>
              </b-card-text>
              <b-card-text v-if="tab.name === 'tree'">
                <div class="tab-content">
                  <div class="block-section">
                    <div class="row">
                      <div class="col-md-12">
                        <TreeChart
                          :json="MemberTree"
                          @click-node="clickNode"
                          @click-child="clickChild"
                        />
                      </div>
                    </div>
                  </div>
                  <b-modal
                    id="infoTree"
                    :width="480"
                    :height="'auto'"
                    :adaptive="true"
                    hide-footer
                    hide-header
                  >
                    <div
                      class="flex items-center title px-5 sm:py-3 border-b border-gray-200 dark:border-dark-5 bg-theme-4"
                    >
                      {{ $t('Member.155') }}: {{ detail.userID }}
                    </div>
                    <div
                      class="grid grid-cols-12 gap-4 row-gap-3"
                      style="padding:20px"
                    >
                      <div class="col-span-12 xl:col-span-6">
                        <label>{{ $t('Member.156') }}</label>
                        <input
                          v-model="detail.email"
                          type="text"
                          class="form-control border flex-1"
                          readonly
                        />
                      </div>
                      <div class="col-span-12 xl:col-span-6">
                        <label>{{ $t('Member.157') }}</label>
                        <input
                          v-model="detail.user_address"
                          type="text"
                          class="form-control w-full border flex-1"
                          placeholder="Not update"
                          readonly
                        />
                      </div>
                      <div class="col-span-12 xl:col-span-6">
                        <label>{{ $t('Member.158') }}</label>
                        <input
                          v-model="detail.userID"
                          type="text"
                          class="form-control w-full border flex-1"
                          readonly
                        />
                      </div>
                      <div class="col-span-12 xl:col-span-6">
                        <label>{{ $t('Member.159') }}</label>
                        <input
                          v-model="detail.totalEggs"
                          type="text"
                          class="form-control w-full border flex-1"
                          readonly
                        />
                      </div>
                      <div class="col-span-12 xl:col-span-6">
                        <label>{{ $t('Member.160') }}</label>
                        <input
                          v-model="detail.totalPool"
                          type="text"
                          class="form-control w-full border flex-1"
                          readonly
                        />
                      </div>
                      <div class="col-span-12 xl:col-span-6">
                        <label>{{ $t('Member.148') }}</label>
                        <input
                          v-model="detail.level_agency"
                          type="text"
                          class="form-control w-full border flex-1"
                          readonly
                        />
                      </div>
                    </div>
                    <div
                      class="px-5 py-3 text-center border-t border-gray-200 dark:border-dark-5 btn-infoTree"
                      style="padding-bottom:20px"
                    >
                      <button
                        type="button"
                        class="button w-20 btn text-white"
                        style="min-width:120px"
                        @click="HideModal"
                      >
                        {{ $t('Transaction.93') }}
                      </button>
                    </div>
                  </b-modal>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <b-modal id="listPackage" hide-footer hide-header>
      <div class="title">{{ $t('Member.161') }}</div>
      <form id="form-deposit" class="form-horizontal" style="padding: 20px;">
        <div class="row">
          <div
            v-for="list in Agency.AllPackage"
            :key="list.id"
            class="col-lg-3 col-md-6 col-sm-6"
          >
            <div class="list-agency">
              <div class="pricingTable-header">
                <h3 class="title">{{ list.name }}</h3>
              </div>
              <div class="icon">
                <img :src="list.image" />
              </div>
              <div class="price-value">
                <span class="amount">${{ list.price }}</span>
              </div>
              <!-- <ul class="pricing-content">
                  <li>50GB Disk Space</li>
              </ul> -->
              <div class="pricingTable-signup">
                <button
                  v-if="list.can_buy == 1"
                  @click="onBuyAgency(list.id)"
                  type="button"
                  class="btn-success"
                  :class="list.price == 0 ? 'opacity-0' : ''"
                >
                  {{ $t('Member.162') }}
                </button>
                <button v-else type="button" class="btn-danger" disabled>
                  {{ $t('Member.162') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <modalCopy :content="content" type="member" />
  </div>
</template>

<script>
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import TreeChart from './Vue-Tree.vue';
  import modalCopy from '@/components/modal/PopupCopy.vue';

  export default {
    components: {
      TreeChart,
      modalCopy,
    },
    data() {
      return {
        mainBalance: 0,
        totalMember: 0,
        totalSubAccount: 0,
        listPackage: '',
        formListMember: {
          page: 1,
          created_at: '',
          user_email: '',
          user_id: '',
        },
        formAddMember: {
          email: '',
          password: '',
          sponsor: '',
          token: '',
        },
        passwordConfirm: '',
        detail: {
          email: '',
          userID: '',
          totalEggs: 'loading',
          totalPool: 'loading',
          level_agency: 'none',
          user_address: '',
        },
        showBtnAddMember: false,
        content: '',
      };
    },
    computed: {
      ...mapState(['isLogin']),
      ...mapGetters({
        Agency: 'dashboard/Agency',
        Total: 'dashboard/Total',
        Balance: 'dashboard/Balance',
        Game: 'dashboard/Game',
        Info: 'dashboard/Info',
        Member: 'member/Member',
        TotalPage: 'member/TotalPage',
        MemberTree: 'member/Tree',
        Detail: 'member/Detail',
        ListCoin: 'wallet/ListCoin',
      }),
      ListMember() {
        return this.Member.listMember;
      },
      TotalPage() {
        return this.Member.totalPage;
      },
      TotalMember() {
        return this.Member.totalMember;
      },
      url() {
        if (this.Game && this.Info && this.Info.id) {
          return `${document.location.origin}/?sponsor=${this.Info.id}`;
        } else {
          return `Please update your wallet to get your link ref`;
        }
      },
      currentTab() {
        return this.tabs[0];
      },
      checkEBP() {
        for (var coin in this.ListCoin) {
          if (coin === 'EBP') {
            return true;
          }
        }
        return false;
      },
      tabs() {
        return [
          { name: 'list', value: this.$t('Message.271') },
          { name: 'add', value: this.$t('Message.272') },
          { name: 'tree', value: this.$t('Message.273') },
        ];
      },
    },
    methods: {
      ...mapMutations(['onLoad', 'outLoad']),
      async onBuyAgency(id) {
        const token = await this.genarateCaptChaV3('buyAgency');
        this.$store.dispatch('dashboard/req_buyAgency', {
          token: token,
          id: id,
        });
      },

      openListPackage() {
        this.$bvModal.show('listPackage');
      },
      onCopy(e) {
        this.content = e.text;
        this.$bvModal.show('modal-copy-member');
      },
      onError(e) {
        alert('Failed to copy texts');
      },
      async fetchMembers(page) {
        try {
          this.formListMember.page = page;
          this.$store.dispatch(
            'member/req_postListMember',
            this.formListMember
          );
        } catch (err) {}
      },
      onSearch() {
        this.fetchMembers(this.formListMember.page);
      },
      onPagination(pageNum) {
        this.fetchMembers(pageNum);
      },
      async onAddMember() {
          this.formAddMember.sponsor = this.Info.id;
          this.onLoad();
          this.formAddMember.token = await this.genarateCaptChaV3('addMember');
          this.$store.dispatch(
            'auth/req_postRegisterMemberChild',
            this.formAddMember
          );
      },
      clickNode(node) {
        this.$store.dispatch('member/req_getMemberDetail', node.id);
        this.modalDetail(node.id, node.name);
      },
      HideModal() {
        this.$bvModal.hide('infoTree');
      },
      clickChild(node) {
        if (node.lastF) {
          this.listMember = this.Member_2;
        }
      },
      modalDetail(ID, Name, totalEggs, totalPool, level_agency) {
        this.detail.userID = ID;
        this.detail.email = Name;
        this.$bvModal.show('infoTree');
      },
      onDefaultTree() {
        this.listMember = this.Member;
      },
      checkRequiredToShowBtnAddMember() {
        if (this.formAddMember.email === '') {
          this.showBtnAddMember = false;
        } else this.showBtnAddMember = true;
      },
    },

    created() {
      if (this.isLogin) {
        this.$store.dispatch('dashboard/req_getAgency');
        this.$store.dispatch('member/req_postListMember', this.formListMember);
        this.$store.dispatch('member/req_getMemberTree');
      }
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        // eslint-disable-next-line default-case
        switch (mutation.type) {
          case 'auth/REGISTER_SUCCESS':
            this.$store.dispatch(
              'member/req_postListMember',
              this.formListMember
            );
            break;
          case 'member/ADD_MEMBER_SUCCESS':
            this.$store.dispatch(
              'member/req_postListMember',
              this.formListMember
            );
            this.$store.dispatch('member/req_getMemberTree');
            break;
          case 'member/GET_DETAIL_MEMBER_SUCCESS':
            this.detail.totalEggs = this.Detail.total_egg;
            this.detail.totalPool = this.Detail.total_pool;
            this.detail.level_agency = this.Detail.level_agency.name;
            this.detail.user_address = this.Detail.user_address;
            break;
          case 'auth/REGISTER_MEMBER_CHILD_SUCCESS':
            this.formAddMember = {
              email: '',
              sponsor: '',
            };
            break;
        }
      });
    },
    beforeDestroy() {
      this.unsubscribe();
    },
  };
</script>
<style lang="scss">
  #listPackage {
    &::-webkit-scrollbar {
      width: 5px;
    }
    @media (max-width: 991px) {
      padding-left: 5px !important;
    }
    .modal-dialog {
      max-width: 900px;
      background-color: #333 !important;
      height: 100%;
      @media (max-width: 767px) {
        margin: 0px;
        margin-top: 50px;
      }
      .modal-body {
        @media (max-width: 991px) {
          height: 100vh;
          overflow-y: auto;
        }
        &::-webkit-scrollbar {
          width: 5px;
        }
      }
    }
  }
  .card {
    border: none;
    background: none;
    .tabs {
      border: none;
      .card-header {
        //   background-color: #ffb800;
        @media (max-width: 480px) {
          height: max-content;
        }
        .nav-item {
          color: #fff;
          .active {
            background-color: #e5e5e588;
            font-weight: bold;
          }
          .nav-link {
            color: #fff;
          }
        }
      }
      .tab-content {
        background-color: rgba(49, 52, 58, 0.8) !important;
        &.active {
          background-color: rgba(49, 52, 58, 0.8) !important;
        }
      }
    }
  }
  .listMember {
    .table-responsive {
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
    .pagination {
      display: flex;
      padding-left: 0px;
      margin: 20px 0px;
      border-radius: 4px;
      font-weight: bold;
      li {
        background-color: rgba(90, 84, 84, 0.288);
        border-radius: 8px;
        width: 40px;
        height: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        margin: 5px;
        &.page-item {
          background: rgba(157, 152, 152, 0.05);
          &.active {
            background: #ffb800;
            color: #2a2e4a;
          }
        }
        &.disabled {
          background-color: rgb(12, 12, 12);
          cursor: not-allowed;
          a {
            cursor: not-allowed;
          }
        }
      }
    }
    .mx-datepicker {
      width: 100%;
      .mx-input-wrapper {
        input {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
            color: rgb(212, 250, 77);
          }
        }
        i {
          color: rgba(153, 164, 176, 0.8);
        }
      }
    }
  }
  #infoTree {
    &.modal {
      padding-left: 0px !important;
      .modal-dialog {
        .modal-content {
          border-radius: 1.25rem;
          background-color: #17181b;
        }
      }
    }
    label {
      color: rgba(153, 164, 176, 0.8);
      margin-top: 20px;
      margin-bottom: 5px;
    }
    input {
      border: 1px solid rgba(84, 86, 88, 0.452);
      background-color: rgba(84, 86, 88, 0.452);
      border-radius: 15px;
      color: rgb(123, 197, 20);
      &:focus {
        background: none;
        border: 1px solid rgb(123, 197, 20);
        outline: none;
        box-shadow: none;
      }
      &::placeholder {
        color: rgb(123, 197, 20);
      }
    }
    .btn-infoTree {
      button {
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #a00000;
        background-image: conic-gradient(from 1turn, #880202, #c51414);
      }
    }
  }
</style>
<style scoped lang="scss">
  .vipClub {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    min-height: 600px;
    width: 100%;
    max-width: 1440px;
    margin: 20px auto;
    position: relative;
    z-index: 10;
    .box-VipClub {
      width: 32%;
      @media (max-width: 1199px) {
        width: 100%;
        margin-bottom: 20px;
      }
      @media (max-width: 991px) {
        width: 48%;
        padding: 0px 10px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      .box-VipClub-title {
        color: white;
        padding: 10px 10px;
        background-color: #5da000;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        h4 {
          margin-bottom: 0px;
          font-size: 16px;
        }
      }
      .box-VipClub-content {
        margin-bottom: 20px;
        height: auto;
        min-height: 200px;
        background: rgb(30 32 36);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px 0px;
        .game {
          width: 100%;
        }
      }
    }
    .box-VipClub2 {
      width: 48%;
      @media (max-width: 1199px) {
        width: 100%;
        margin-bottom: 20px;
      }
      @media (max-width: 991px) {
        width: 48%;
        padding: 0px 10px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      .box-VipClub-title {
        color: white;
        padding: 10px 10px;
        background-color: #5da000;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        h4 {
          margin-bottom: 0px;
          font-size: 16px;
        }
      }
      .box-VipClub-content {
        margin-bottom: 20px;
        height: auto;
        min-height: 200px;
        background: rgb(30 32 36);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px 0px;
        .game {
          width: 100%;
        }
      }
    }
    .box-VipClub3 {
      width: 100%;
      .box-VipClub-title {
        color: white;
        padding: 10px 10px;
        background-color: #5da000;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        h4 {
          margin-bottom: 0px;
          font-size: 16px;
        }
      }
      .box-VipClub-content {
        margin-bottom: 20px;
        height: auto;
        min-height: 200px;
        background: rgb(30 32 36);
        display: flex;
        justify-content: center;
        align-content: flex-start;
        flex-wrap: wrap;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0px;
        .card {
          width: 100%;
          display: flex;
          justify-content: center;
          .form-add-member {
            width: 100%;
            max-width: 600px;
            padding: 10px;
            border: 1px solid #dbe1e8;
            border-radius: 10px;
            margin: 0 auto;
            p {
              color: rgba(158, 158, 158, 0.678);
              font-weight: 600;
              margin-top: 15px;
              margin-bottom: 5px;
            }
            .form-control {
              border: 1px solid rgba(84, 86, 88, 0.452);
              background-color: rgba(84, 86, 88, 0.452);
              border-radius: 15px;
              color: rgb(123, 197, 20);
              &:focus {
                background: none;
                border: 1px solid rgb(123, 197, 20);
                outline: none;
                box-shadow: none;
              }
            }
            button {
              display: block;
              width: 11.25rem;
              height: 3rem;
              margin: 1.25rem auto 0px;
              color: rgb(245, 246, 247);
              box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
              background-color: #5e5555;
              background-image: conic-gradient(
                from 1turn,
                rgb(69, 136, 2),
                rgb(123, 197, 20)
              );
              font-weight: bold;
              cursor: pointer;
              border-radius: 6.25rem;
              border: none;
              .button-inner {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                width: 100%;
                height: 100%;
              }
              &.btn-disable {
                cursor: not-allowed;
                background-image: conic-gradient(
                  from 1turn,
                  rgba(69, 136, 2, 0.418),
                  rgba(123, 197, 20, 0.425)
                );
              }
            }
          }
        }
        .game {
          width: 100%;
        }
      }
    }
  }
  .button-custom {
    height: 30px;
    box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #458802, #7bc514);
    border-top-right-radius: 5px;
    border: 0 none !important;
    border-bottom-right-radius: 5px;
    color: white;
    max-width: 40%;
    margin: 0 !important;
    padding: 0 5px;
    font-size: 15px;
    @media (max-width: 450px){
      border-radius: 5px;
      max-width: 100% !important;
    }
  }
  .box-VipClub-content {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .listMember {
    .form-control {
      border: 1px solid #2d3035;
      background-color: rgba(84, 86, 88, 0.452);
      border-left: none;
      border-radius: 15px;
      color: #d4fa4d;
    }
    .table-responsive {
      border: 1px solid rgba(229, 229, 229, 0.26);
      border-radius: 10px;
      max-height: 500px;
      overflow-y: auto;
      table {
        border: none;
        color: #fff;
        position: relative;
        thead {
          border: none;
          background: #ffb800;
          color: #2f2f2f;
          position: sticky;
          top: 0;
          z-index: 1;
          th {
            border: none;
            td {
              border: none;
            }
          }
        }
        tbody {
          border: 0;
          tr {
            border: 0;
            background: none;
            &:nth-child(2n + 2) {
              background: rgba(229, 229, 229, 0.26);
            }
            &:hover {
              color: rgb(102, 255, 0);
            }
            td {
              border: none;
            }
          }
        }
      }
      .block-section {
        color: rgb(163, 163, 163);
        padding-left: 20px;
      }
    }
    button {
      height: 100%;
      width: 7.25rem;
      color: #f5f6f7;
      box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
      background-color: #5da000;
      background-image: conic-gradient(from 1turn, #458802, #7bc514);
      border-radius: 6.25rem;
      font-weight: bold;
      border: none;
    }
  }
</style>

<style lang="scss" scoped>
  .member-ib {
    display: flex;
  }
  .opacity-0 {
    opacity: 0;
  }
  .list-agency {
    background: #fff;
    text-align: center;
    margin-bottom: 15px;
    padding: 20px 0 35px;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.3);
  }
  .list-agency .icon {
    width: 120px;
    margin: 0 auto 15px;
    min-height: 140px;
  }
  .list-agency .icon img {
    width: 100%;
  }
  .list-agency .title {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 35px;
    background: #fff0;
    margin: 0;
  }
  .list-agency .price-value {
    color: #000;
    padding: 0 15px;
    margin: 0 0 20px;
  }
  .list-agency .price-value .amount {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    color: #f89923;
  }
  .list-agency .price-value .duration {
    font-size: 30px;
    font-weight: 300;
    text-transform: lowercase;
  }
  .list-agency .pricing-content {
    text-align: left;
    padding: 25px 15px;
    margin: 0 0 30px;
    border: 1px solid #e7e7e7;
    border-left: none;
    border-right: none;
    list-style: none;
    display: inline-block;
  }
  .list-agency .pricing-content li {
    color: #777;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    padding: 0 0 0 40px;
    margin: 0 0 12px;
    position: relative;
  }
  .list-agency .pricing-content li:last-child {
    margin-bottom: 0;
  }
  .list-agency .pricing-content li:before {
    content: '\f00c';
    color: #fff;
    background: #f89923;
    font-family: 'Font Awesome 5 Free';
    font-size: 12px;
    font-weight: 900;
    text-align: center;
    line-height: 24px;
    width: 23px;
    height: 23px;
    border-radius: 50px;
    position: absolute;
    top: 1px;
    left: 0;
  }
  .list-agency .pricing-content li.disable:before {
    content: '\f00d';
    background: #ed1925;
  }
  .list-agency .pricingTable-signup button {
    color: #fff;
    background: linear-gradient(#72bc4b, #54a445);
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 7px 15px;
    border-radius: 5px;
    border: none;
    display: inline-block;
    transition: all 0.3s ease;
  }
  .list-agency .pricingTable-signup button:disabled {
    background: darkred;
    cursor: not-allowed;
    pointer-events: all !important;
  }
  .list-agency .pricingTable-signup a:hover {
    text-shadow: 4px 4px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.15);
  }
  .list-agency .pricingTable-header {
    background: linear-gradient(
      to right,
      #8dc53c,
      #e1e530 20px,
      #e1e530 13px,
      #8dc53c 38px,
      #8dc53c 40%,
      #9bbf3b 60%
    );
  }
  .pricingTable .pricing-content li:before {
    background: #54a445;
  }
  .pricingTable .pricing-content li.disable:before {
    background: #ed1925;
  }
  .pricingTable-header {
    text-align: center;
    text-transform: uppercase;
    padding: 0px 0;
    margin: 0 20px 20px 0;
    clip-path: polygon(0 0, 0 100%, 85% 100%, 100% 50%, 85% 0);
  }
  .img-div {
    flex: 45%;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
  }
  .title {
    text-align: center;
    box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #458802, #7bc514);
    padding: 5px 0;
    font-size: 15px;
    font-weight: 700;
    color: white;
    border-radius: 10px;
  }
  .img-div img {
    max-width: 100px;
    max-height: 140px;
  }
  .detail-div {
    flex: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
    align-self: center;
    font-size: 30px;
    font-weight: 800;
    color: #ffa300;
  }
  .button-custom {
    height: 30px;
    box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
    background-color: #5da000;
    background-image: conic-gradient(from 1turn, #458802, #7bc514);
    border-top-right-radius: 5px;
    border: 0 none !important;
    border-bottom-right-radius: 5px;
    color: white;
    max-width: 40%;
    margin: 0 !important;
    padding: 0 5px;
    font-size: 15px;
  }
  .input-custom {
    width: 70%;
    height: 30px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 0 none !important;
    text-align: center;
    font-size: 15px;
    padding: 0;
    @media (max-width: 450px){
      width: 96%;
      border-radius: 5px;
      margin-bottom: 5px;
    }
  }
  .input-custom2 {
    width: 70%;
    height: 30px;
    border-radius: 5px;
    border: 0 none !important;
    text-align: center;
    font-size: 15px;
    padding: 0;
  }
  .widget {
    min-height: 155px;
  }
  .box-VipClub-content {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .game-title-2 {
    color: #ffa300;

    font-weight: 800;
  }
  .mx-2 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .game {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    justify-self: center;

    padding-bottom: 5px;

    width: 100%;
  }

  .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    padding-right: 15px;
  }
  .right-content .group-button .btn {
    min-width: 90px;
  }
  .left-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    align-self: center;
    padding-left: 15px;
    flex-direction: column;
  }

  @media (max-width: 500px) {
    .game {
      flex-direction: column;
      border-bottom: 2px solid #c8973b;
    }
  }
  .game-title {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  .game-balance {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
  }
</style>
